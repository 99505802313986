import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import SEO from "./seo"
import Wrapper from "./Wrapper"
import Headline from "./Headline"
import Navbar from "./Navbar"
import Heading from "./Heading"

import ContactContent from "./ContactContent"
import ContactForm from "./ContactForm"
import ContactAddress from "./ContactAddress"

import Footer from "./Footer"

import HeaderImage from "../images/contact-header-img.png"

const useStyles = makeStyles( theme => ({
  sectionCategoryListWrapper:{
    [theme.breakpoints.down("md")]: {
      flexDirection:'column'
    }
  }
}))

const Contact = ({ pageContext }) => {
  // console.log(pageContext)
  const caption = pageContext.dataPage.textDeskripsi
  const classes = useStyles()
    // "Contact us about anything related to our company or services. We'll do our best to get back to you as soon as possible"

  return (
    <Wrapper whatsappNumber={pageContext.dataPage.whatsappNumber} >
      <SEO title="Contact" />
      <Headline />
      <Navbar />
      <Heading minHeight="25rem" background={pageContext.dataPage.gambarBanner.url} />

      <Box
        className="section-card-list-wrapper"
        width="100%"
        display="flex"
        justifyContent="center"
        paddingY="5.625rem"
      >
        <Box
          className="category-list-wrapper"
          width="80%"
          display="flex"
          justifyContent="space-between"
          className={classes.sectionCategoryListWrapper}
        >
          <ContactContent
            heading={pageContext.dataPage.heading}
            caption={caption}
            maxWidth="28.438rem"
          >
            <ContactAddress data={pageContext.dataPage} />
          </ContactContent>
          <ContactForm emailPenerima={pageContext.footer.emailPenerima}/>
        </Box>
      </Box>
      <Footer data={pageContext.dataPage}/>
    </Wrapper>
  )
}

export default Contact
