import React from "react"
import { Box, Typography } from "@material-ui/core"
import useStyles from "./style"
import LocationIcon from "../../images/location-icon.svg"
import PhoneGreenIcon from "../../images/phone-green-icon.svg"
import EmailIcon from "../../images/email-icon.svg"
import ClockIcon from "../../images/clock-icon.svg"
const ContactAddress = (props) => {
  const classes = useStyles()
  const { address, emailAddress, workingTime, phoneNumber} = props.data

  return (
    <Box className={classes.root}>
      <Box mb="35px" display="flex" className={classes.addressBox}>
        <Box mr="12px">
          <img src={LocationIcon} alt="location-icon" />
        </Box>
        <Box>
          <Box mb="5px">
            <Typography variant="body1">Address</Typography>
          </Box>
          <Box>
            <Typography variant="body2">
              {address}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mb="35px" display="flex" className={classes.phoneBox}>
        <Box mr="12px">
          <img src={PhoneGreenIcon} alt="phone-icon" />
        </Box>
        <Box>
          <Box mb="5px">
            <Typography variant="body1">Phone</Typography>
          </Box>
          <Box>
            <Typography variant="body2">{phoneNumber}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mb="35px" display="flex" className={classes.phoneBox}>
        <Box mr="12px">
          <img src={EmailIcon} alt="email-icon" />
        </Box>
        <Box>
          <Box mb="5px">
            <Typography variant="body1">Email Address</Typography>
          </Box>
          <Box>
            <Typography variant="body2">{emailAddress}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mb="35px" display="flex" className={classes.phoneBox}>
        <Box mr="12px">
          <img src={ClockIcon} alt="clock-icon" />
        </Box>
        <Box>
          <Box mb="5px">
            <Typography variant="body1">Working Time</Typography>
          </Box>
          <Box>
            <Typography variant="body2" dangerouslySetInnerHTML={ {__html: workingTime} }>
                { /* <table>
                    <tr>
                        <td>Monday - Friday</td>
                        <td> : </td>
                        <td> 9am - 6pm </td>
                    </tr>
                    <tr>
                        <td>Saturday</td>
                        <td> : </td>
                        <td> 10am - 3pm </td>
                    </tr>
                    <tr>
                        <td>Sunday</td>
                        <td> : </td>
                        <td> Closed </td>
                    </tr>
                </table> */}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactAddress
